
import { Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import EditTableMenu from "@/components/reusable/editable_table/EditTableMenu.vue";
import EditTable from "@/components/reusable/editable_table/EditTable.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { DimensionModel } from "@/models/dimension";
import { EventBus } from "@/events/index";
@Component({
  components: {
    draggable,
    Icon,
    EditTableMenu,
    FormInput,
    Autocomplete
  }
})
export default class DimEditTable extends EditTable {
  @Prop({
    default: () => [] as DimensionModel[]
  })
  data!: DimensionModel[];
  @Prop({ default: false }) disableDrag!: boolean;

  mounted() {
    EventBus.$on("checkAttributes", () => this.removeBlank());
    if (this.data.length < 1) {
      this.tableData = [];
      this.addRow();
    } else {
      this.tableData = [...this.data];
      this.updateEditTags();
    }
  }

  @Watch("tableData", { deep: true, immediate: false })
  protected onUpdate(): void {
    this.$emit("update", this.tableData);
    if (this.tableData.length < 1) {
      this.addRow();
    }
  }

  beforeDestroy() {
    EventBus.$off("checkAttributes");
  }

  protected toggleEdit(rowId: number, boolean = true) {
    this.isEditTags.forEach(row => {
      if (row.id === rowId) {
        row.isEdit = boolean;
      }
    });
  }
  protected addRow(): string {
    const id = this.randomStringId();
    const newObj: any = { id: id, isEdit: true };
    this.isEditTags.push(newObj);
    const emptyRow: DimensionModel = {
      label: "",
      value: "",
      unit: "",
      id: id,
      idx: 0
    };
    this.tableData.push(emptyRow);
    return id;
  }

  /**
   * isEditTags holds an id  & associated boolean which toggles edit on
   * row with matching id.
   *
   * This method iterates through array of objects and builds an object to represent each table row in isEditTags
   * @example ...
   * [{id: 12, isEdit: false}, { id: 22, isEdit: false}]
   */
  protected updateEditTags(): void {
    this.isEditTags = this.tableData.map(row => {
      return { id: row.id, isEdit: false };
    });
  }

  /** Returns whether input is editable */
  public editInput(rowId: number): boolean | null {
    const row: any[] = this.isEditTags.filter(row => {
      if (row.id === rowId) {
        return row; // return matching item in isEditTags (row will = { id: number, isEdit: boolean} )
      }
    });
    if (row[0]) {
      // if row exists, return isEdit value (boolean)
      return row[0].isEdit;
    } else {
      return null; // no match, no change
    }
  }
  protected removeBlank(): void {
    this.tableData = this.tableData.filter(row => row.label);

    this.updateEditTags();
  }
    
  protected handleKeyDown(event: KeyboardEvent): void {
    if (event.code === "Space") {
      event.cancelBubble = true;
    }
  }
}
