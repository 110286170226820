
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import EditTableMenu from "@/components/reusable/editable_table/EditTableMenu.vue";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalGetters } from "@/store/modules/global/types";

@Component({
  components: {
    draggable,
    Icon,
    EditTableMenu
  }
})
export default class EditTable extends Vue {
  @Prop() disableDrag!: boolean;
  protected tableData: any[] = [];
  protected drag = false;
  protected moveCursor = false;
  protected isEditTags: any[] = [];

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  protected randomStringId(): string {
    return "new" + Math.floor(Math.random() * 10000);
  }

  protected deleteValue(rowId: number): void {
    this.tableData = this.tableData.filter(row => {
      return row.id !== rowId;
    });
  }
}
